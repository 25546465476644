import React from "react";
import HeaderBlock from "./slices/HeaderBlock";
import FeatureGrid from "./slices/FeatureGrid";
import FeatureGridA from "./slices/FeatureGridA";
import FeatureStrip from "./slices/FeatureStrip";
import FeatureStripA from "./slices/FeatureStripA";
import LifestyleImage from "./slices/LifestyleImage";
import UseCaseCarousel from "./slices/UseCaseCarousel";
import PricingSection from "./slices/PricingSection";
import Testimonials from "./slices/Testimonials";
import SplitBlock from "./slices/SplitBlock";

const SliceZone = ({ sliceZone, trackPage }) => {
  // console.log({ sliceZone });
  const sliceComponents = {
    header_block: HeaderBlock,
    feature_grid: FeatureGrid,
    "feature_picker_-_grid": FeatureGridA,
    lifestyle_image: LifestyleImage,
    feature_strip: FeatureStrip,
    "feature_picker_-_strip": FeatureStripA,
    use_case_carousel: UseCaseCarousel,
    pricing_module: PricingSection,
    testimonials: Testimonials,
    split_block: SplitBlock,
  };

  const sliceZoneContent = sliceZone.map((slice, index) => {
    const SliceComponent = sliceComponents[slice.type];
    if (SliceComponent) {
      return (
        <SliceComponent
          slice={slice}
          trackPage={trackPage}
          key={`slice-${index}`}
        />
      );
    }
    return <p key={`slice-${index}`}>Missing component for "{slice.type}"</p>;
  });

  return <main>{sliceZoneContent}</main>;
};

export default SliceZone;
